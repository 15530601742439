import { Link, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {useModal} from "../context";
import Feedback from "./modals/Feedback";
function Footer({screen, moveDown}) {
    const location = useLocation();
    const [inner, setInner] = useState(false);
    const { show, hide} = useModal();

    useEffect(() => {
        setInner(location.pathname != '/');
    }, [location.pathname]);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
    }

    return (
        <>
            <div className={'footer' + (location.pathname === '/end' ? ' footer--thin' : '')}>
                <div className="container">
                    <div className="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.mars.com/privacy-policy-russian" target="_blank">Конфиденциальность</a>
                            </li>
                            <li>
                                <a href="https://www.mars.com/legal-russia" target="_blank">Юридические условия</a>
                            </li>
                            <li>
                                <a href="https://mars.com/" target="_blank">Владелец сайта</a>
                            </li>
                            {/*<li>
                                <a onClick={()=>{
                                    show(<Feedback product={true}/>, "modal--form modal--feedback");
                                }}>Задать вопрос о продукте</a>
                            </li>*/}
                            <li>
                                <a href="/user-agreement.pdf" target="_blank">Пользовательское соглашение</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__legal text-center">

                    </div>
                    <div className="footer__rights">
                        © 2024 Mars, Incorporated. Все права защищены
                    </div>
                </div>
                {/*{location.pathname == '/' && (
                    <div onClick={()=>{
                        document.getElementById('promo').scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                    }} className="up d-flex align-items-center justify-content-center">
                        <div className="up__ct">
                            <div className="up__icon">
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 15.1483V1.70361" stroke="#8E1348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1 8.42595L8 1.70361L15 8.42595" stroke="#8E1348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="up__title text-center">
                                Наверх
                            </div>
                        </div>
                    </div>
                )}*/}
            </div>
        </>
    );
}

export default Footer;
