import Countdown, {zeroPad} from 'react-countdown';

const declOfNum = function(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
        (number % 100 > 4 && number % 100 < 20)
            ?
            2
            :
            cases[(number % 10 < 5) ? number % 10 : 5]
        ];
}

function Start() {
    const renderer = ({ days, hours, minutes }) => (
        <div className="counter__list d-flex justify-content-center">
            <div className="counter__it">
                <div className="counter__it-val d-flex align-items-center justify-content-center">
                    <span>{zeroPad(days)}</span>
                </div>
                <div className="counter__it-lbl">
                    {declOfNum(days, ['день', 'дня', 'дней'])}
                </div>
            </div>
            <div className="counter__it">
                <div className="counter__it-val d-flex align-items-center justify-content-center">
                    <span>{zeroPad(hours)}</span>
                </div>
                <div className="counter__it-lbl">
                    {declOfNum(hours, ['час', 'часа', 'часов'])}
                </div>
            </div>
            <div className="counter__it">
                <div className="counter__it-val d-flex align-items-center justify-content-center">
                    <span>{zeroPad(minutes)}</span>
                </div>
                <div className="counter__it-lbl">
                    {declOfNum(minutes, ['минута', 'минуты', 'минут'])}
                </div>
            </div>
        </div>
    );

    return(
        <>
            <div className="sc__top desk-only">
                <div className="top__ct d-flex align-items-center justify-content-center">
                    <div className="top__decor">
                        <img src={require("../assets/img/top-decor.svg").default}/>
                    </div>
                    <div className="top__title">
                        Акция «Тому, кто научил важному» подошла к концу
                    </div>
                    <a href="/archive" target="_blank" className="top__btn d-flex align-items-center justify-content-center">
                        Подробности
                    </a>
                </div>
            </div>
            <div className="sc__start">
                <div className="start__bg"/>
                <div className="logo">
                    <img src={require("../assets/img/logo.png")}/>
                </div>
                <div className="container d-flex align-items-center">
                    <div className="start__ct">
                        <div className="start__header d-flex">
                            <div className="start__title gradient-text-2">
                                <span>Акция</span> <br/>
                                начнется через
                            </div>
                        </div>
                        <Countdown
                            date={'2024-11-01T10:00:00+03:00'}
                            renderer={renderer}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Start;
